<template>
  <v-app class="ma-0 pa-0">
    <v-toolbar dark
               prominent
               height="500"
               :src="require(`@/assets/banner.jpg`)">
      <template v-slot:img="{ props }">
        <v-img v-bind="props"
               gradient="to right bottom, rgba(33, 59, 86, 0.898), rgba(25, 64, 72, 0.2)"></v-img>
      </template>
      <div>
        <v-img class="mr-3 logoImage"
               src="@/assets/logo.png">
        </v-img>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon
             href="https://www.facebook.com/aroxproizvodnja/"
             target="_blank">
        <v-icon>mdi-facebook</v-icon>
      </v-btn>

      <v-btn icon
             href="https://www.instagram.com/aroxproizvodnja/"
             target="_blank">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>

      <v-btn icon
             href="https://www.linkedin.com/company/arox-proizvodnja-d-o-o"
             target="_blank">
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs :align-with-title="!isMobile"
                :centered="isMobile"
                :grow="isMobile">
          <v-tab v-for="(link, index) in links"
                 :key="index"
                 @click="scrollToAnchor(link.anchor)">{{link.title}}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12"
               lg="8"
               offset-lg="2">
          <v-main>
            <PageTemplate :anchor="anchor"
                          @resetScrollPosition="resetScrollPosition" />
          </v-main>
        </v-col>
      </v-row>
    </v-container>
    <v-footer color="primary lighten-1"
              padless>
      <v-row justify="center"
             no-gutters>
        <v-btn v-for="(link, index) in links"
               :key="index"
               color="white"
               text
               rounded
               @click="scrollToAnchor(link.anchor)"
               class="my-2">
          {{ link.title }}
        </v-btn>
        <v-col class="primary lighten-2 py-4 text-center white--text"
               cols="12">
          {{ new Date().getFullYear() }} — <strong>Arox proizvodnja</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import PageTemplate from './layouts/PageTemplate';
import isMobile from 'ismobilejs';

export default {
  name: 'App',

  components: {
    PageTemplate,
  },
  data() {
    return {
      isMobile: false,
      anchor: null,
      links: [
        { title: 'Proizvodi', anchor: 'product' },
        { title: 'Katalozi', anchor: 'catalog' },
        { title: 'Kontakt', anchor: 'contact' },
      ],
    }
  }, methods: {
    scrollToAnchor(value) {
      this.anchor = value;
    },
    resetScrollPosition(value) {
      this.anchor = value;
    }
  },
  created() {
    this.isMobile = isMobile().any;
  },
  mounted() {
    console.log({ router: this.$router });
  }
};
</script>
<style lang="scss">
html,
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}
.logoImage {
  width: 110px !important;
  height: 48px !important;
  @media screen and (max-width: 800px) {
    width: 80px !important;
    height: 35px !important;
  }
}
</style>

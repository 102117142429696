<template>
  <div>
    <div class="overline mt-10 primary--text text--lighten-1"
         id="product">Proizvodi</div>
    <v-divider class="my-5"></v-divider>
    <Products />
    <div class="overline mt-10 primary--text text--lighten-1"
         id="catalog">Katalozi</div>
    <v-divider class="my-5"></v-divider>
    <Catalogs />
    <div class="overline mt-10 primary--text text--lighten-1"
         id="contact">Kontakt</div>
    <v-divider class="my-5"></v-divider>
    <Contact />
    <div class="text-h4 mt-10 font-weight-medium text-center"></div>
  </div>
</template>

<script>
import Products from '../components/Products.vue';
import Catalogs from '../components/Catalogs.vue';
import Contact from '../components/Contact.vue';

export default {
  props: ['anchor'],
  components: {
    Products,
    Catalogs,
    Contact,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.$emit('resetScrollPosition', null);
    }
  },
  watch: {
    anchor(newValue) {
      if (newValue) {
        document.getElementById(newValue).scrollIntoView({
          behavior: "smooth"
        });
      }

    }
  },
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <v-card>
    <v-img height="250">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7559.237324096246!2d17.540508538017136!3d43.1907245092761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9b85d3f04ea1237a!2sArox!5e1!3m2!1sen!2sba!4v1551802372295"
              width="100%"
              height="100%"
              frameborder="0"
              style="border:0"
              class="map"
              allowfullscreen></iframe>
      <div id="googleMapsOverlay">
      </div>
    </v-img>
    <v-row>
      <v-col cols="12"
             sm="6">
        <div v-for="(contact, index) in contactInfo"
             :key="index">
          <v-card-title class="justify-center pb-1 pt-6">
            {{contact.title}}
          </v-card-title>
          <a class="text-center contactHrefs"
             v-html="contact.label"
             :href="contact.href">
          </a>
        </div>

      </v-col>
      <v-col cols="12"
             sm="6">
        <v-card-title class="justify-center pb-0 mb-0">Radno vrijeme</v-card-title>

        <v-card-text class="justify-center">
          <div class="text-center">
            <p class="ma-0 pa-0 mt-4 mb-1">Pon - Pet</p>
            <v-chip color="primary lighten-2"
                    class="mx-2">08:00</v-chip>
            <v-chip color="primary lighten-1"
                    class="mx-2">17:00</v-chip>
          </div>
          <div class="text-center">
            <p class="ma-0 pa-0 mt-4 mb-1">Subota</p>
            <v-chip color="primary lighten-2"
                    class="mx-2">08:00</v-chip>
            <v-chip color="primary lighten-1"
                    class="mx-2">13:00</v-chip>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
    <v-divider class="ma-4"></v-divider>

  </v-card>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
    contactInfo: [
      { title: 'Adresa', label: 'Sestre Silvije Antić 6,<br>88320 Ljubuški' },
      { title: 'Email', label: 'arox@arox-doo.com', href: 'mailto: arox@arox-doo.com' },
      { title: 'Telefon', label: '(+387) 039 832-353', href: 'tel: (+387) 039 832-353' },
    ]
  }),

  methods: {
    reserve() {
      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    },
  },
}
</script>
<style lang="scss" scoped>
.contactHrefs {
  text-align: center;
  display: block;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}
.map {
  filter: saturate(90%);
}
#googleMapsOverlay {
  width: 100%;
  height: 100%;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    to right bottom,
    rgba(33, 59, 86, 0.898),
    rgba(25, 64, 72, 0.2)
  );
  position: absolute;
  opacity: 0.5;
  top: 0px;
  left: 0px;
  z-index: 99;
  pointer-events: none;
}
</style>
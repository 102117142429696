<template>
  <v-card class="ma-4 pa-0">
    <v-img class="productImage"
           :aspect-ratio="1/1"
           :src="require(`@/assets/products/${product.name}.jpg`)"></v-img>

    <v-card-title class="text-button font-weight-bold py-1">{{product.label}}</v-card-title>

    <v-card-text>
      <div class="overline ma-0">
        {{product.category.toUpperCase()}}
      </div>

      <div>{{product.description}}</div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ['product'],
  data: () => ({
    loading: false,
    selection: 1,
  }),

  methods: {
    reserve() {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    },
  },
}
</script>
<style lang="scss" scoped>
.productImage {
  min-width: 180px !important;
  min-height: 180px !important;
  @media screen and (max-width: 800px) {
    min-width: 250px !important;
    min-height: 250px !important;
  }
}
</style>
<template>
  <div>
    <v-card class="ma-4 pa-0 catalogWrapper">
      <v-responsive :aspect-ratio="16 / 9">
        <v-img :src="require(`@/assets/products/${catalog.img}.jpg`)"
               :aspect-ratio="4 / 3"
               gradient="to right bottom, rgba(33, 59, 86, 0.898), rgba(25, 64, 72, 0.2)">
          <v-container fill-height
                       fluid
                       pa-2>
            <v-layout fill-height>
              <v-flex xs12
                      align-end
                      flexbox>
                <h1 class="text-h5 font-weight-thin mb-3 white--text">{{ catalog.label }}</h1>
                <v-btn text
                       class="font-weight-medium mb-3 white--text"
                       color="white"
                       :href="catalog.url"
                       target="_blank">Pregledajte
                  <v-icon right>
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-img>
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['catalog'],
  data() {
    return {

    };
  },

};
</script>

<style lang="scss" scoped>
.catalogWrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<template>
  <div>
    <div>
      <v-row justify="start"
             class="ma-n4">
        <v-col v-for="product, index in products"
               :key="index"
               cols="12"
               xs="12"
               sm="6"
               md="6"
               lg="3"
               xl="3"
               class="pa-0 ma-0 productWrapper">
          <Product :product="product" />

        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Product from './Product';
export default {
  components: {
    Product,
  },
  data() {
    return {
      products: [
        {
          name: 'fiso',
          category: 'Ležaljka',
          description: 'Plastična ležaljka za plažu.',
          label: 'FISO bijela'
        }, {
          name: 'scirocco',
          category: 'Ležaljka',
          description: 'Plastična ležaljka za plažu.',
          label: 'SCIROCCO bijela'
        }, {
          name: 'fiso',
          category: 'Ležaljka',
          description: 'Plastična ležaljka za plažu.',
          label: 'Fiso bijela'
        }, {
          name: 'fiso',
          category: 'Ležaljka',
          description: 'Plastična ležaljka za plažu.',
          label: 'Fiso bijela'
        }
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
.productWrapper {
  display: inline;
}
</style>
<template>
  <div>
    <div>
      <v-row justify="start"
             class="ma-n4">
        <v-col v-for="catalog, index in catalogs"
               :key="index"
               cols="12"
               xs="12"
               sm="6"
               md="6"
               class="pa-0 ma-0 productWrapper">
          <Catalog :catalog="catalog" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Catalog from './Catalog';
export default {
  components: {
    Catalog,
  },
  data() {
    return {
      catalogs: [
        {
          img: 'catalogGarden',
          url: 'https://drive.google.com/file/d/1ATQbeWvgTpc3mVvb4I9hAUpk0NPD0jQj/view?usp=sharing',
          label: 'Katalog Garden furniture'
        }, {
          img: 'catalogHouseware',
          url: 'https://drive.google.com/file/d/1VR7VezQjpJJHGyWmGqsfI8oHQeyz4gU_/view?usp=sharing',
          label: 'Katalog Houseware'
        },
      ],

    }
  },
}
</script>

<style lang="scss" scoped>
.productWrapper {
  display: inline;
}
</style>